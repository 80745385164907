import debounce from "lodash.debounce";
import { useEffect, useMemo, useRef } from "react";

/**
 * Debounces the given function
 * @param duration - The number of ms to wait before letting a value pass
 * @param functionToDebounce - The onChange function to debounce
 */
export const useDebounce = <T>(
  functionToDebounce: (...value: T[]) => void,
  duration: number = 500
) => {
  const functionRef = useRef(functionToDebounce);

  useEffect(() => {
    functionRef.current = functionToDebounce;
  }, [functionToDebounce]);

  const debounceFunction = useMemo(
    () => debounce((...args) => functionRef.current(...args), duration),
    [duration]
  );

  useEffect(() => {
    return () => {
      debounceFunction.cancel();
    };
  }, [debounceFunction]);

  return debounceFunction;
};
