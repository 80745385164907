import { FunctionComponent, ReactNode, useEffect, useRef } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  ActionButtonProps,
  dataAttribute,
  DataAttributes,
  FontSizes,
  FontWeights,
  ILinkStyles,
  IMaskedTextFieldProps,
  IRenderFunction,
  ITextField,
  ITextFieldProps,
  Link,
  MaskedTextField,
  mergeFuncStyles,
  mergeStyleSets,
  Stack,
  TextField,
  toKebabCase
} from "@bps/fluent-ui";

import {
  ExposedFieldProps,
  getFieldErrorMessage,
  getLabelStyles
} from "./FinalForm.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

type HtmlElementType = HTMLInputElement | HTMLTextAreaElement;

export interface TextFieldActionButtonProps
  extends Omit<ActionButtonProps, "onRender"> {
  onRender?: () => React.ReactNode;
}

export type TextInputFieldProps = Omit<
  IMaskedTextFieldProps,
  "data" | "type" | "value" | "onChange" | "defaultValue" | "description"
> &
  ExposedFieldProps<
    IMaskedTextFieldProps["value"] | number,
    Omit<HtmlElementType, "type" | "defaultValue">
  > & {
    validateOnInitialize?: boolean;
    actionButton?: TextFieldActionButtonProps;
    type?: "password" | "email";
    readOnly?: boolean;
    trimmed?: boolean;
    hint?: string;
  };

export type TextFieldAdapterProps = Pick<
  FieldRenderProps<IMaskedTextFieldProps["value"], HtmlElementType>,
  "input" | "meta"
> &
  Omit<IMaskedTextFieldProps, "description"> & {
    validateOnInitialize?: boolean;
    actionButton?: TextFieldActionButtonProps;
    trimmed?: boolean;
    hint?: string;
  };

export const TextFieldAdapter = (props: TextFieldAdapterProps) => {
  const {
    input,
    required,
    label,
    meta,
    styles,
    data,
    readOnly,
    onRenderLabel: onRenderLabelProps,
    autoFocus,
    maxLength,
    trimmed,
    hint,
    ...textFieldProps
  } = props;

  const fieldRef = useRef<ITextField | null>(null);
  const errorMessage = getFieldErrorMessage(meta, props.validateOnInitialize);

  useEffect(() => {
    if (autoFocus && fieldRef.current) {
      fieldRef.current?.focus();
    }
  }, [autoFocus]);

  const { meta: formMeta } = useField(input.name);

  const disabled = textFieldProps.disabled || formMeta?.data?.disabled;

  const newStyles = mergeStyleSets(
    {
      subComponentStyles: {
        label: mergeFuncStyles(getLabelStyles(meta), { root: { padding: 0 } }),
        button: {
          root: {
            fontSize: FontSizes.small,
            fontWeight: FontWeights.regular
          }
        } as Partial<ILinkStyles>
      },
      errorMessage: {
        whiteSpace: "initial"
      },
      description: {
        display: "block",
        paddingTop: 5,
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.regular
      }
    },
    styles
  );

  const onRenderLabelDefault: IRenderFunction<ITextFieldProps> = (
    labelProps,
    defaultRendering
  ): JSX.Element | null => {
    const { label, required } = props;
    if (label && defaultRendering) {
      return defaultRendering({
        label,
        disabled,
        required
      });
    }
    return null;
  };

  const onRenderLabel = onRenderLabelProps || onRenderLabelDefault;

  const onRenderActionButton = (
    prop: TextFieldAdapterProps
  ): ReactNode | null => {
    if (prop.actionButton) {
      const { linkProps, onRender } = prop.actionButton;
      const childrenName =
        typeof linkProps?.children === "string"
          ? `-${toKebabCase(linkProps?.children)}`
          : "";

      const labelName = label ? `-${toKebabCase(label)}` : "";

      const automationTestAttribute = `form-item-link${labelName}${childrenName}`;

      if (onRender) {
        return onRender();
      }
      return (
        <Link
          {...linkProps}
          {...dataAttribute(DataAttributes.Element, automationTestAttribute)}
          disabled={disabled}
          styles={mergeFuncStyles(
            newStyles.subComponentStyles.button,
            linkProps?.styles
          )}
        />
      );
    }

    return null;
  };

  const commonProps: IMaskedTextFieldProps = {
    autoComplete: "off",
    ...input,
    ...textFieldProps,
    id: props?.id ?? input.name,
    disabled,
    "aria-label": props?.id ?? input.name,
    styles: newStyles,
    onRenderLabel: (labelProps, defaultRendering) => {
      return (
        <>
          {(onRenderLabelProps || props.label || props.actionButton) && (
            <Stack
              horizontal
              horizontalAlign="space-between"
              styles={{ root: { padding: "5px 0" } }}
            >
              {onRenderLabel(labelProps, defaultRendering)}
              {onRenderActionButton(props)}
            </Stack>
          )}
        </>
      );
    },
    errorMessage,
    description: !errorMessage ? hint : undefined,
    onChange: (
      evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: string | undefined
    ) => {
      const newValue =
        trimmed && !!value && value.length ? value.trim() : value;

      if (maxLength && newValue && newValue?.length > maxLength) {
        return false;
      }

      return input.onChange(newValue);
    }
  };

  if (readOnly || formMeta?.data?.readOnly) {
    if (props.input.value) {
      return (
        <ReadOnlyTextField
          value={props.input.value}
          label={props.label}
          styles={{ ...styles }}
        />
      );
    }
    return null;
  }

  return textFieldProps.mask ? (
    <MaskedTextField
      componentRef={r => {
        if (r && !fieldRef.current) {
          fieldRef.current = r;
        }
      }}
      {...commonProps}
    />
  ) : (
    <TextField
      componentRef={r => {
        if (r && !fieldRef.current) {
          fieldRef.current = r;
        }
      }}
      {...commonProps}
    />
  );
};

export const TextInputField: FunctionComponent<TextInputFieldProps> = props => (
  <Field {...props} component={TextFieldAdapter} />
);
