import { FocusEvent, forwardRef } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DocumentEditorContainer,
  DocumentEditorContainerComponent
} from "@syncfusion/ej2-react-documenteditor";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";
import { AutofillContext } from "@ui-components/text-editors/editor-autofills/editor-autofill.types.ts";
import { SyncFusionWordProcessor } from "@ui-components/text-editors/SyncFusionWordProcessor.tsx";

import { ExposedFieldProps } from "./FinalForm.tsx";

type InternalDocumentEditorContainerProps = Partial<
  Pick<DocumentEditorContainer, "height">
> & { autofillContext: AutofillContext };

type SyncFusionDocumentWriterProps = ExposedFieldProps<string, HTMLElement> &
  InternalDocumentEditorContainerProps;

type SyncFusionDocumentWriterAdapterProps = FieldRenderProps<string> &
  InternalDocumentEditorContainerProps;

export const SyncFusionDocumentWriterAdapter = forwardRef<
  DocumentEditorContainerComponent,
  SyncFusionDocumentWriterAdapterProps
>(({ input, height, autofillContext }, ref) => {
  const { onBlur, onFocus, onChange } = input;
  const { core } = useStores();
  const onContentChange = useDebounce(onChange);

  return (
    <SyncFusionWordProcessor
      ref={ref}
      onContentChanged={onContentChange}
      onFocus={evt => onFocus(evt as FocusEvent<HTMLElement>)}
      onBlur={evt => onBlur(evt as FocusEvent<HTMLElement>)}
      height={height}
      withClinicalAutofill={core.hasPermissions(Permission.AutofillAllowed)}
      autofillContext={autofillContext}
    />
  );
});

export const SyncFusionWordProcessorField = forwardRef<
  DocumentEditorContainerComponent,
  SyncFusionDocumentWriterProps
>((props, ref) => {
  return (
    <Field {...props} component={SyncFusionDocumentWriterAdapter} ref={ref} />
  );
});
