import { reaction } from "mobx";
import { ReactNode, useEffect, useState } from "react";
import { FormSpy, useFormState } from "react-final-form";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Dialog,
  DialogFooter,
  IDialogContentStyles,
  IDialogProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Spinner,
  useTheme
} from "@bps/fluent-ui";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FORM_PROMPT_DEFAULT_MESSAGE } from "@ui-components/form/FinalForm.tsx";

import { defaultButtonsText, Prompt } from "../prompt/Prompt.tsx";
import { FormSubmitButtons } from "../submission-form/FormSubmitButtons.tsx";
import { SubmissionFormProps } from "../submission-form/SubmissionForm.types.ts";

export interface DialogWithSubmitButtonsProps<
  FormValues extends object = object
> extends Pick<SubmissionFormProps<FormValues>, "hideButtons"> {
  /**
   * Optional prop for data passing data to AppInsights and GA.
   */
  dialogName: string;
  dialogProps: Omit<IDialogProps, "onDismiss" | "hidden"> & {
    onDismiss: () => void;
    showTitleSeparator?: boolean;
  };
  promptDialogProps?: {
    confirmButtonProps?: ConfirmDialogProps["confirmButtonProps"];
    cancelButtonProps?: ConfirmDialogProps["cancelButtonProps"];
  };

  submitButtonProps?: SubmissionFormProps<FormValues>["buttonsProps"];
  children?: ReactNode;
  /**
   * Renders Fallback component if we prefetch data for the form.
   */
  loadingData?: boolean;
  /**
   * Loading data Fallback component. Default is Spinner.
   */
  Fallback?: ReactNode;
  /**
   * Renders error MessageBar component in a case of getting error while form data fetching.
   */
  dataLoadingError?: string;
  disablePrompt?: boolean;
  lockAnyRouting?: boolean;
}

export const DialogWithSubmitButtons = <FormValues extends object = object>({
  promptDialogProps,
  dialogName,
  submitButtonProps,
  dialogProps,
  hideButtons,
  children,
  loadingData,
  dataLoadingError,
  Fallback,
  disablePrompt,
  lockAnyRouting
}: DialogWithSubmitButtonsProps<FormValues>) => {
  const { core, routing } = useStores();
  const theme = useTheme();

  const [isClosing, setIsClosing] = useState<boolean>();
  const { dirty, submitSucceeded, submitting, dirtySinceLastSubmit } =
    useFormState();

  const isFormDirty: boolean =
    (dirty && !submitSucceeded && !submitting) || dirtySinceLastSubmit;

  useEffect(() => {
    // close dialog on route pathname change if form is not dirty
    const disposeReaction = reaction(
      () => routing.location.pathname,
      () => !isFormDirty && dialogProps.onDismiss()
    );

    return () => {
      disposeReaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDialogOpenedAnalytics(dialogName, core.user?.fullName);

  const handleClosing = () => {
    if (isFormDirty && !disablePrompt) {
      setIsClosing(true);
    } else {
      dialogProps.onDismiss();
    }
  };

  const dialogContentStyles: Partial<IDialogContentStyles> = {
    title: {
      borderBottom: dialogProps.showTitleSeparator
        ? `1px solid ${theme.palette.neutralLight}`
        : undefined
    },
    subText: {
      paddingLeft: theme.spacing.l1,
      paddingRight: theme.spacing.l1
    },
    content: {
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    inner: { padding: 0, minHeight: 0 },
    innerContent: {
      display: "flex",
      flexDirection: "column",
      paddingTop: dialogProps.showTitleSeparator ? 24 : undefined,
      height: "100%"
    }
  };

  return (
    <>
      <Dialog
        {...dialogProps}
        onDismiss={handleClosing}
        hidden={false}
        dialogContentProps={{
          showCloseButton: true,
          ...dialogProps.dialogContentProps,
          styles: mergeStyleSets(
            dialogContentStyles,
            dialogProps?.dialogContentProps?.styles
          )
        }}
        isClickableOutsideFocusTrap
        forceFocusInsideTrap={false}
      >
        <>
          {/* Renders Fallback component (Spinner as default) while data fetching */}
          {loadingData && (Fallback ?? <Spinner />)}
          {/* Renders MessageBar component when getting data fetching error */}
          {!loadingData && !!dataLoadingError && (
            <MessageBar messageBarType={MessageBarType.error}>
              {dataLoadingError}
            </MessageBar>
          )}
          {!loadingData && !dataLoadingError && (
            <>
              {children}
              {!hideButtons && (
                <DialogFooter
                  styles={{
                    actionsRight: { margin: 0 },
                    actions: { margin: 0 },
                    action: { margin: 0 }
                  }}
                >
                  <FormSpy<FormValues>>
                    {({ form }) => {
                      const formSubmitButtonsProps =
                        typeof submitButtonProps === "function"
                          ? submitButtonProps(form)
                          : submitButtonProps;
                      return (
                        <FormSubmitButtons
                          hideButtonsSeparator={true}
                          {...formSubmitButtonsProps}
                          styles={mergeStyleSets(
                            formSubmitButtonsProps?.styles,
                            {
                              root: { padding: 24 }
                            }
                          )}
                          onCancel={handleClosing}
                        />
                      );
                    }}
                  </FormSpy>
                </DialogFooter>
              )}
            </>
          )}
        </>
      </Dialog>
      {/* Route prompt to prevent dialog closing if form is dirty  */}
      {(isFormDirty || lockAnyRouting) && (
        <Prompt
          promptPredicate={() => FORM_PROMPT_DEFAULT_MESSAGE}
          onConfirmed={dialogProps.onDismiss}
          hideConfirmation={lockAnyRouting}
        />
      )}

      {!disablePrompt && (
        <ConfirmDialog
          dialogContentProps={{
            subText: FORM_PROMPT_DEFAULT_MESSAGE,
            subTextId: "modal-prompt-dialog-id"
          }}
          confirmButtonProps={{ text: defaultButtonsText.confirm }}
          cancelButtonProps={{ text: defaultButtonsText.cancel }}
          hidden={!(isClosing && isFormDirty)}
          onCancel={() => setIsClosing(false)}
          onConfirm={() => {
            setIsClosing(false);
            dialogProps.onDismiss();
          }}
          {...promptDialogProps}
        />
      )}
    </>
  );
};
