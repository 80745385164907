import { Field, FieldRenderProps } from "react-final-form";

import {
  ITag,
  mergeStyleSets,
  StaticMultiTagPicker,
  StaticMultiTagPickerProps,
  useTheme
} from "@bps/fluent-ui";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";

type HtmlElementType = any;

export type StaticTagPickerAdapterProps = StaticMultiTagPickerProps &
  Pick<FormItemFieldProps, "required" | "label" | "actionButton"> & {
    formItemFieldStyles?: FormItemFieldProps["styles"];
  };

export type StaticTagPickerFieldProps = ExposedFieldProps<
  StaticMultiTagPickerProps["selectedKeys"],
  HtmlElementType
> &
  StaticTagPickerAdapterProps;

const StaticTagPickerAdapter = (
  props: FieldRenderProps<
    StaticMultiTagPickerProps["selectedKeys"],
    HtmlElementType
  > &
    StaticTagPickerAdapterProps
) => {
  const {
    meta,
    input,
    label,
    styles,
    required,
    onChange,
    inputProps,
    actionButton,
    formItemFieldStyles,
    ...pickerProps
  } = props;

  const { semanticColors } = useTheme();
  const hasErrorMessage = !!getFieldErrorMessage(meta);
  const newStyles = mergeStyleSets(
    {
      text: hasErrorMessage && {
        borderColor: semanticColors.errorText,
        selectors: {
          "&:focus, &:hover": {
            borderColor: semanticColors.errorText
          }
        }
      }
    },
    styles
  );

  const onChangeInternal = (
    selectedItems: string[] | undefined,
    items: ITag[]
  ) => {
    input.onChange(selectedItems);
    // input re-obtains the focus on change but onFocus is not triggered
    // https://github.com/OfficeDev/@fluentui/react/blob/b982a68f449a61b16429d1a025cd8a949177d954/packages/@fluentui/react/src/components/pickers/BasePicker.tsx#L875
    input.onFocus();
    if (onChange) {
      onChange(selectedItems, items);
    }
  };

  return (
    <FormItemField
      styles={formItemFieldStyles}
      name={input.name}
      required={required}
      label={label}
      actionButton={actionButton}
    >
      <StaticMultiTagPicker
        {...pickerProps}
        onChange={onChangeInternal}
        selectedKeys={input.value}
        inputProps={{
          ...inputProps,
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          autoComplete: "new-password"
        }}
        withTagItemProps={() => ({
          styles: { root: { flexGrow: 1, justifyContent: "space-between" } }
        })}
        styles={newStyles}
      />
    </FormItemField>
  );
};

const normalizeToArray = (value: any) => (Array.isArray(value) ? value : []);

/**
 * Use StaticTagPickerField to use StaticTagPicker with final-form.
 * It also wraps the picker in a FormItemField to support label and error message.
 * @param props
 */
export const StaticTagPickerField: React.FC<
  StaticTagPickerFieldProps
> = props => (
  <Field
    {...props}
    format={normalizeToArray}
    parse={normalizeToArray}
    component={StaticTagPickerAdapter}
  />
);
